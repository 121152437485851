let timelineElements = [
  {
    id: 1,
    title:
      "Data Engineer - Data & Reporting - Wealth Management Platform at UBS",
    location: "Zurich, Switzerland",
    description:
      "(i) Reporting Data Mart design and implementation. (ii) Building Data pipelines to source data into the analytics data warehouse.",
    buttonText: "UBS",
    date: "Feb 2024 - present",
    icon: "work",
    buttonLink: "https://www.ubs.com",
    skills: [
      "Dimension Modelling",
      "Python",
      "Requirements Engineering",
      "SQL",
    ],
    skills_t: "Systems Architecture | Python",
  },
  {
    id: 2,
    title:
      "Senior Technical Business Analyst - Trade Settlements & Custody Services, Vice President at Credit Suisse AG",
    location: "Zurich, Switzerland",
    description:
      "(i) Analysis and Documentation of the existing end-to-end Trade Settlement process for Brokerage & Custody Services. Created different stakeholder perspectives of the business process flow and of the applications involved, to support the impact assessment of the T+1 Settlement directive. (ii) Data Analysis to derive insights on the readiness to move to the T+1 Settlement Cycle in the US/CA markets. Programmed Python scripts for data analysis to evaluate the gaps to be addressed.",
    buttonText: "Credit Suisse",
    date: "May 2023 - Jan 2024",
    icon: "work",
    buttonLink: "https://www.credit-suisse.com",
    skills: [
      "Business Architecture",
      "Python",
      "Requirements Engineering",
      "PUML",
      "UML",
    ],
    skills_t: "Systems Architecture | Python",
  },
  {
    id: 3,
    title: "IT Architect, Vice President at Credit Suisse AG",
    location: "Zurich, Switzerland",
    description:
      "Designed strategic distributed systems integration architectural patterns within the Risk and Compliance division for second line of defense applications, integrating Big Data ecosystems such as Palantir Foundry, leveraging TOGAF framework and Agile methodologies and coordinate approvals from architecture authority boards.",
    buttonText: "Credit Suisse",
    date: "June 2021 - April 2023",
    icon: "work",
    buttonLink: "https://www.credit-suisse.com",
    skills: [
      "Systems Architecture",
      "Business Architecture",
      "Python",
      "TOGAF",
      "Requirements Engineering",
      "PUML",
      "UML",
    ],
    skills_t: "Systems Architecture | Python",
  },
  {
    id: 4,
    title: "Business Advisor at BearingPoint AG",
    location: "Zurich, Switzerland",
    description:
      "Delivered Data Modelling solutions, incorporating DWH design, performed data integration, ETL activities, built BI/Data Analytics and Reporting Dashboard for several clients. Implemented software solutions at major international (re)insurance companies, conducting business analysis and requirements engineering workshops, specifically in the Regulatory Risk and Compliance area.",
    buttonText: "BearingPoint",
    date: "August 2012 — April 2021",
    icon: "work",
    buttonLink: "https://www.bearingpoint.com",
    skills: [
      "Systems Architecture",
      "Business Architecture",
      "Data Modelling",
      "Python",
      "PL/SQL",
      "Oracle",
      "MSSQL",
      "SAP Analytics Cloud",
      "Power BI",
      "Data Analytics",
      "R",
    ],
    skills_t:
      "Systems Architecture | Data modelling | Python, PL/SQL | Oracle | MSSQL | SAP Analytics Cloud, R",
  },
  {
    id: 5,
    title: "Senior IT Consultant at Credit Suisse Asset Management",
    location: "Zurich, Switzerland",
    description:
      "Supported application development/integration activities, Configuration Management, including planning and managing baselines.",
    buttonText: "Credit Suisse",
    date: "April 2011 — June 2012",
    icon: "work",
    buttonLink: "https://www.credit-suisse.com",
    skills: ["PL/SQL", " Oracle"],
    skills_t: "PL/SQL | Oracle",
  },
  {
    id: 6,
    title: "Senior IT Consultant at Credit Suisse AG",
    location: "Zurich, Switzerland",
    description:
      "Performed development/integration activities, implemented automation with workflow solutions as part of the HRIS and Payroll transformation project using PeopleSoft ERP System.",
    buttonText: "Credit Suisse",
    date: "July 2008 — December 2008",
    icon: "work",
    buttonLink: "https://www.credit-suisse.com",
    skills: ["PeopleSoft", "PL/SQL", "Oracle"],
    skills_t: "PeopleSoft | PL/SQL | Oracle",
  },
  {
    id: 7,
    title: "ERP Senior Technical Consultant at HBK Investments, USA",
    location: "Zurich, Switzerland (Remote)",
    description:
      "Designed and implemented an ERP/JavaScript AJAX based issue tracking system with advanced typeahead search functionalities, which also tracked projects, tasks, milestones, and enhancement requests related to applications including a custom workflow component. This helped enhance business user engagement through improved user experience.",
    buttonText: "HBK Investments",
    date: "June 2007 — July 2008",
    icon: "work",
    buttonLink: "https://www.hbk.com",
    skills: ["PeopleSoft", "Javascript"],
    skills_t: "PeopleSoft | Javascript",
  },
  {
    id: 8,
    title:
      "ERP Principal Technical Consultant at Cognizant Corp, (Clients: City of Lakeland, Florida, IndyMac Bank, MetLife, Credit Suisse AG)",
    location: "New York, USA and Zurich, Switzerland",
    description:
      "Implementated ERP solutions, performed software development activities on Payroll transformation projects, including workflow re-engineering. Led the functional and technical Implementation of PeopleSoft HCM and Financial applications for several clients as part of HRIS/Payroll transformation projects. Responsible for technical development, business requirements analysis, issue resolution and coordination of offshore development team activities, adopting the Waterfall SDLC methodology.",
    buttonText: "Cognizant",
    date: "May 2001 — April 2007",
    icon: "work",
    buttonLink: "https://www.cognizant.com",
    skills: ["PeopleSoft", "PL/SQL", "Oracle"],
    skills_t: "PeopleSoft | PL/SQL | Oracle",
  },
  {
    id: 9,
    title: "ERP Senior Developer at Cognizant Corp",
    location: "Chennai, India",
    description:
      "As a Senior developer on a major company-wide HRIS application landscape transformation project, developed the required functionalities in the target PeopleSoft HCM system and led the migration of data and HR and Payroll processes from the legacy HRIS systems to the PeopleSoft ERP system.",
    buttonText: "Cognizant",
    date: "June 1999 — April 2001",
    icon: "work",
    buttonLink: "https://www.cognizant.com",
    skills: ["PeopleSoft", "Oracle"],
    skills_t: "PeopleSoft | Oracle",
  },
];

export default timelineElements;
